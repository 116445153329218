import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import ExternalLink from '../shared/external-link'
import moment from 'moment'
const Exposition = ({ trans }) => {
  const intl = useIntl()

  const Img = intl.messages[`${trans}.image`]

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const writeDate = trans => {
    const startDate = intl.messages[`${trans}.startdate`]
    const endDate = intl.messages[`${trans}.enddate`]
    const date = intl.messages[`${trans}.date`]
    const from = intl.formatMessage({ id: 'expositions.from' })
    const to = intl.formatMessage({ id: 'expositions.to' })

    let output = ''
    if (startDate && endDate) {
      output = `${from} ${moment(startDate).format('LL')} ${to} ${moment(
        endDate
      ).format('LL')}`
    } else if (startDate) {
      output = `${from} ${moment(startDate).format('LL')}`
    } else if (endDate) {
      output = `${to} ${moment(endDate).format('LL')}`
    } else if (date) {
      // single day
      output = `${moment(date).format('LL')}`
    }
    output = capitalizeFirstLetter(output)
    return output
  }

  return (
    <li>
      <h3>
        {intl.messages[`${trans}.link`] ? (
          <ExternalLink href={intl.messages[`${trans}.link`]}>
            <FormattedMessage id={`${trans}.title`} />
          </ExternalLink>
        ) : (
          <FormattedMessage id={`${trans}.title`} />
        )}
      </h3>
      {intl.messages[`${trans}.description`] && (
        <p>
          <FormattedMessage id={`${trans}.description`} />
        </p>
      )}
      {writeDate(trans)}

      {intl.messages[`${trans}.location`] && (
        <div className="location">
          <FormattedMessage id={`locatie`} />:{' '}
          <FormattedMessage id={`${trans}.location`} />
        </div>
      )}
      {intl.messages[`${trans}.image`] && <Img />}
      <br />
    </li>
  )
}
export default Exposition
