import React from 'react'

import { FormattedMessage } from 'react-intl'
import './style.scss'
import Navigation from '../components/navigation'

import Exposition from '../components/exposition'
import withLayout from '../components/layout'
import expositions from '../i18n/translations/nl/expositions'
import moment from 'moment'

const ExpositionsPage = () => {
  const now = moment()
  const current = []
  const upcomming = []
  const past = []

  Object.entries(expositions.items).forEach(([key, exposition]) => {
    let endDate = false
    if (exposition.enddate) endDate = moment(exposition.enddate)
    else if (exposition.date) endDate = moment(exposition.date).add(1, 'day')

    let startDate = false
    if (exposition.startdate) startDate = moment(exposition.startdate)
    else if (exposition.date) startDate = moment(exposition.date)

    const noDate = !startDate && !endDate

    if (now.isBetween(startDate, endDate) || exposition.permanent) {
      current.push(key)
    } else if (!now.isAfter(startDate) && !noDate) {
      upcomming.push(key)
    } else {
      past.push(key)
    }
  })

  return (
    <>
      <header className="small_header">
        <div className="inline">
          <Navigation />
        </div>
      </header>
      <article className="info inline expositions">
        <h2>
          <FormattedMessage id="current_expositions" />:
        </h2>
        <ul>
          {current.map((current, i) => (
            <Exposition key={i} trans={`expositions.items.${current}`} />
          ))}
        </ul>

        {upcomming.length > 0 && (
          <>
            <h2>
              <FormattedMessage id="upcomming" />:
            </h2>
            <ul>
              {upcomming.map((upcomming, i) => (
                <Exposition key={i} trans={`expositions.items.${upcomming}`} />
              ))}
            </ul>
          </>
        )}

        <h2>
          <FormattedMessage id="past" />:
        </h2>

        <ul className="list">
          {past.map((past, i) => (
            <Exposition key={i} trans={`expositions.items.${past}`} />
          ))}
        </ul>
      </article>
    </>
  )
}
const customProps = {
  localeKey: 'expositions', // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(ExpositionsPage)
